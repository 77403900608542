<template>
    <div>
        <div v-if="!selectedFile" class="flex flex-col items-center justify-center pt-32">
            <label class="w-64 flex flex-col items-center px-4 py-6 text-highlight-textLarge tracking-wide border border-highlight-base hover-bg-highlight-200 cursor-pointer">
                <Icon name="upload" class="w-8 h-8 text-highlight-base" />
                <span class="mt-2 text-base leading-normal">select a file</span>
                <input @change="setFile()" type="file" ref="file" :accept="accept" class="hidden" />
            </label>
            <p v-if="title" class="pt-4">{{ title }}</p>
            <p v-else class="pt-4">Please select a <code class="text-gray-500 text-sm">.csv</code> or <code class="text-gray-500 text-sm">.xlsx</code> file to upload.</p>
        </div>
        <div v-if="selectedFile" class="flex flex-col items-center justify-center pt-32">
            <label class="w-64 flex flex-col items-center px-4 py-6 text-highlight-200 tracking-wide bg-highlight-base">
                <Icon name="checkmark" class="w-8 h-8 text-highlight-200" />
                <span class="mt-2 text-base leading-normal">file selected</span>
            </label>
            <p class="pt-4 cursor-pointer flex items-center text-sm">
                 {{truncate(this.selectedFile.name, {length: 45})}}
            </p>
            <button class="mt-4 flex items-center btn-primary btn-no-border" @click="clearFile">
                <Icon name="close" class="w-3 h-3 mr-1" /> clear file
            </button>
        </div>
    </div>
</template>

<script>
import {truncate} from "lodash-es";
import Icon from "@/components/ui/Icon";

export default {
    components: { Icon },
    props: {
        accept: {
            type: String,
            required: false
        },
        title: {
            type: String,
            required: false
        }
    },
    emits: ['input'],
    data: () => {
        return {
            selectedFile: null,
        }
    },
    methods: {
        truncate,
        setFile() {
            this.selectedFile = this.$refs.file.files[0];
            this.$emit('input', this.selectedFile);
        },
        clearFile() {
            this.selectedFile = null;
            this.$emit('input', this.selectedFile);
        }
    }
}
</script>
