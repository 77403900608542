<template>
  <div class="h-full flex flex-col p-20">
    <loader :loading="loading" :backdrop="true" />
    <div class="form-error mb-4" v-if="isSync">
      You can't import availability because it already comes from an external api
    </div>
    <div class="flex flex-1 overflow-auto flex-col">
      <p class="font-bold text-l text-black mb-1">1. Download the import template</p>
      <p class="font-bold text-l text-black mb-1">2. Populate the template with your current availability information.</p>
      <p class="font-bold text-l text-black mb-1">3. Give that file a unique name and save it. Make sure it is saved as a .csv file</p>
      <p class="font-bold text-l text-black">4. Upload that file.</p>
      <div class="flex justify-center items-center -mt-20 -mb-5">
        <file-input class="mr-32" v-model="uploadedFile" accept=".csv" title="upload availability import." v-on:input="validateFile" ></file-input>
        <div class="flex  flex-col items-center justify-center mt-32 ml-32" @click="startDownload">
          <label class="w-64 flex flex-col items-center px-4 py-6 text-highlight-textLarge tracking-wide border border-highlight-200 hover-bg-highlight-200 cursor-pointer">
              <Icon name="save" class="w-8 h-8 text-highlight-base" />
              <span class="mt-2 text-base leading-normal">download</span>
          </label>
          <p v-if="uploadedFile" class="pt-4 pb-8">download import template.</p>
          <p v-else class="pt-4">download import template.</p>
        </div>
      </div>
      <div class="mt-12">
        <div v-if="this.fileErrors" class="mb-6">
          <span class="form-error my-2" v-for="error in fileErrors" :key="error">{{ error }}</span>
        </div>
        <p class="font-bold text-l text-black text-center">Important! importing an availability report will delete all of your current availability within the Digital Human and replace it with the imported availability. This is not an add or an incremenetal change.</p>
      </div>
    </div>
    <modal-footer 
    :footer="footer" 
    :primary="() => { 
      if(!isSync) uploadFile()
    }" :tertiary="close"></modal-footer>
  </div>
</template>

<script>
import AuthMixin from '@/components/auth/AuthMixin'
import ModalNavigation from '@/mixins/ModalNavigation'
import Loader from '@/components/ui/Loader'
import FileInput from "@/components/ui/FileInput";
import ModalFooter from '@/components/ui/modals/ModalFooter'
import NotifyMixin from '@/mixins/NotifyMixin'
import Icon from "@/components/ui/Icon";
import EventBus from "@/utils/EventBus"
export default {
  mixins: [AuthMixin, ModalNavigation, NotifyMixin],
  components: {
    Loader,
    FileInput,
    ModalFooter,
    Icon
  },
  data() {
    return {
      loading: false,
      footer: {
          primaryButton: 'import',
          tertiaryButton: 'cancel'
      },
      uploadedFile: null,
      titleExtension: '',
      isSync: false,
      fileErrors: null,
    }
  },
  watch: {
    community: function () {
      if (this.community?.id )
        this.fetchCommunity()
      else
        this.notifyError('please select a community to continue, then refresh the browser');
     
    }
  },
  methods: {
    getDataParams(file) {
      if (!this.community || this.community?.id.trim() === '') {
        return null;
      }

      if(!file){
        this.notifyError('Please select a file and try again.');
        return null;
      }

      let formData = new FormData();
      formData.append('file', file, "Proposed Availability Upload Template.csv");
      formData.append('communityID',  this.community?.id);
      formData.append('customerID', this.profile.customerId);

      return { data: formData }
    },
    uploadFile() {
        if(this.fileErrors) {
          this.notifyError('Please use a file with no errors shown');
          return;
        }

        const formDataParams = this.getDataParams(this.uploadedFile);

        if (!formDataParams) {
          return null;
        }

        this.loading = true;
 
        this.$dhDataProvider.create('uploadAvailability', formDataParams).then((response) => {
            if (response.status === 200) {
              this.loading = false;
              this.notifySuccess('units uploaded successfully.')
              this.$router.push({name: 'availability_pricing.index'})
           
            }
            else{
              this.notifyError(response.response)
              this.loading = false;
              this.$router.push({name: 'availability_pricing.index'})
            }
        })
        .catch((error) => {
          this.notifyError(error.message)
          this.loading = false;
        })
    },
    startDownload() {
      this.titleExtension = 'csv'
      EventBus.emit('show-modal-download', { title: 'Download PDF', downloadStarted: 'download started'});
    },
    downloadProcess(data) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `template-unit.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    onDownload() {
      if (!this.community || this.community?.id.trim() === '' ) {
        return null;
      }
      this.$dhDataProvider.get('unitAvailabilityTemplate', {
        headers: {
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'Content-disposition': 'attachment',
        }})
      .then(response => {
        this.downloadProcess(response);
      })
      .catch((error) => this.notifyError(error))
      .finally(() => this.hideDownloadIndicator())
    },
    fetchCommunity() {
      this.loading = true;
      this.$dhDataProvider
        .get('communityByCustomer', { idCommunity: this.community?.id, idCustomer: this.profile?.customerId })
        .then((response) => {
          this.isSync = response.sync
        })
        .catch((err) => this.notifyError(err.message))
        .finally(() => {
         this.loading = false;
        })
    },
    validateFile(file) {
        if(!file) {
          return this.fileErrors = null;
        }

        const formDataParams = this.getDataParams(file);

        if (!formDataParams) {
          return null;
        }

        this.loading = true;

        this.$dhDataProvider.create('validateAvailability', formDataParams).then((response) => {
          if(typeof response === 'object' &&
          !Array.isArray(response) &&
          response !== null) {
            delete response.status;
            this.fileErrors = response;
          }
          if(response.length > 0) {
            this.fileErrors = response;
          }
        })
        .catch((error) => this.notifyError(error))
        .finally(() => this.loading = false);
    },
  },
  created() {
    this.fetchCommunity();
    if (!this.community) this.notifyError('please select a community to continue, then refresh the browser');
  },
  mounted() {
    EventBus.on('download-pdf', () => {
      switch (this.titleExtension) {
        case 'csv':
          this.onDownload();
          break;
        default:
          this.titleExtension = ''
          break;
      }
    })
  },
  unmounted(){
    EventBus.off('download-pdf');
  },
}
</script>